import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import { BlogPostSectionRenderer } from '../section-renderer/blog-post'

const breadcrumbsBlog = [{ url: '/blog/', name: 'Blog' }]

const BlogPostPage = ({ data: { page, next, previous, recommended, products } }) => {
  const sections = page?.sections || []
  const firstSection = sections[0]
  const otherSections = sections.slice(1)

  recommended.nodes.forEach(post => {
    processGatsbyImageDataMock(post.heroImage)
  })
  processGatsbyImageDataMock(page.heroImage)

  return (
    <BlogPostSectionRenderer
      breadcrumbs={breadcrumbsBlog}
      page={page}
      next={next}
      previous={previous}
      recommended={recommended}
      products={products}
      firstSection={firstSection}
      otherSections={otherSections}
    />
  )
}

BlogPostPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          content: PropTypes.string,
          videos: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
          strapi_component: PropTypes.string,
        })
      ),
      title: PropTypes.string,
      url: PropTypes.string,
      publishedAt: PropTypes.string,
      table_of_contents: PropTypes.bool,
      seo: PropTypes.shape({
        metaRobots: PropTypes.string,
        metaViewport: PropTypes.string,
        keywords: PropTypes.string,
        canonicalURL: PropTypes.string,
        structuredData: PropTypes.shape({
          internal: PropTypes.shape({
            content: PropTypes.string,
          }),
        }),
        metaTitle: PropTypes.string.isRequired,
        metaDescription: PropTypes.string.isRequired,
        metaSocial: PropTypes.arrayOf(
          PropTypes.shape({
            socialNetwork: PropTypes.oneOf(['Facebook', 'Twitter']),
            title: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.shape({
              twitterSocialImage: PropTypes.string,
              facebookSocialImage: PropTypes.string,
            }),
          })
        ),
      }),
      heroImage: PropTypes.object,
    }),
    next: PropTypes.object,
    previous: PropTypes.object,
    recommended: PropTypes.object,
    products: PropTypes.object,
  }),
}

export const query = graphql`
  query BlogPostQuery($pageId: Int!, $next: Int, $previous: Int) {
    page: strapiBlogPost(strapi_id: { eq: $pageId }) {
      title
      url: urlPath
      createdAt
      publishedAt(formatString: "MM/DD/YYYY")
      updatedAt
      heroImage {
        gatsbyImageDataMock
      }
      seo {
        ...SEO
      }
      table_of_contents
      sections {
# ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
#   ...ImageSection
# }
        ... on STRAPI__COMPONENT_SECTION_NUMBERED_LIST {
          ...NumberedListSection
        }
        ... on STRAPI__COMPONENT_SECTION_SECTION_LAYOUT {
          ...SectionLayout
        }
        ... on STRAPI__COMPONENT_SECTION_ACCORDION_SECTION {
          ...AccordionSection
        }
        #                ... on STRAPI__COMPONENT_SHARED_YOUTUBE_VIDEOS {
        #                    title
        #                    url
        #                    strapi_component
        #                }
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
      }
    }
    next: strapiBlogPost(strapi_id: { eq: $next }) {
      title
      url: urlPath
    }
    previous: strapiBlogPost(strapi_id: { eq: $previous }) {
      title
      url: urlPath
    }
    products: allStrapiProduct(limit: 5, sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...ProductCard
      }
    }
    recommended: allStrapiBlogPost(limit: 7, sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        title
        url: urlPath
        heroImage {
          gatsbyImageDataMock
        }
        publishedAt(formatString: "MM/DD/YYYY")
        tag {
          category
        }
      }
    }
  }
`

export default BlogPostPage
